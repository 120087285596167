
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/stateless/AppInput.vue';

export default defineComponent({
  name: 'Inputs',

  components: { AppInput },

  setup() {

    const input1 = ref<string>('');

    return { input1 };
  }
});
