<template>
  <div class="text-primary text-3xl text-center p-7 border-b border-grey-fp-30">Inputs</div>
  <div class="py-10 mb-18">
    <p class="text-1xl text-grey-fp-50 mb-20">Sizes</p>
    <div class="flex items-center">
      <AppInput
        v-model="input1"
        label="Large"
        name="large"
        size="large"
        class="mr-18"
        maxlength="100"
      />
      <AppInput
        v-model="input1"
        label="Medium"
        name="medium"
        class="mr-18"
        maxlength="100"
      />
    </div>
  </div>
  <div class="py-10 mb-18">
    <p class="text-1xl text-grey-fp-50 mb-20">Disabled</p>
    <div class="flex items-center">
      <AppInput
        v-model="input1"
        disabled
        label="Title"
        placeholder="Placeholder"
        name="disabled"
        maxlength="100"
      />
    </div>
  </div>
  <div class="py-10 mb-18">
    <p class="text-1xl text-grey-fp-50 mb-20">Error</p>
    <div class="flex items-center">
      <AppInput
        v-model="input1"
        label="Error"
        name="error"
        error="Error message"
        maxlength="100"
      />
    </div>
  </div>
  <div class="py-10 mb-18">
    <p class="text-1xl text-grey-fp-50 mb-20">Password</p>
    <div class="flex items-center">
      <AppInput
        v-model="input1"
        label="Password"
        name="password"
        type="password"
        maxlength="100"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';

import AppInput from '@/components/stateless/AppInput.vue';

export default defineComponent({
  name: 'Inputs',

  components: { AppInput },

  setup() {

    const input1 = ref<string>('');

    return { input1 };
  }
});
</script>